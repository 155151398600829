.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#step-one .bp4-label, #step-two .bp4-label, #step-three .bp4-label, #step-four .bp4-label {
  text-align: left;
}

#step-one.container, #step-two.container, #step-three.container {
  width: 60%
}

#step-one .bp4-form-group, #step-two .bp4-form-group, #step-three .bp4-form-group {
  padding: 5px;
}


.money-preview {
  background: whitesmoke;
  height: 200px;
  width: 100%;
  border-radius: 5px;
  transition: 0.2s;
}

.money-preview:hover {
  box-shadow: 3px 3px 5px 0px silver;
}

.money-preview h4 {
  padding-top: 25%;
}
.details-section {
  text-align: left;
}

.details-section p {
  margin-bottom: 2px;
  border-bottom: 1px solid whitesmoke;
}

.boxshadowhover {
  transition: 0.2s;
}

.selectedPlan {
  box-shadow: 3px 3px 5px 0px lightgrey;
}

.boxshadowhover:hover {
  box-shadow: 3px 3px 5px 0px silver;
}

.login-form {
  width: 400px;
  margin: 0 auto;
}

.field-column {
  text-align: right;
}

.upload-area {
  min-height: 300px;
  width: 50%;
  padding: 15px;
}


.upload-area .inner-border {
  min-height: 300px;
  width: 100%;
  border: 3px dashed lightblue;
  cursor: pointer;
}

.inner-border input {
  width: 100%;
  height: 300px;
}

.upload-title {
  position: relative;
  top: -150px;
}

.img {
  width: 100px;
  height: 120px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.hidden {
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0)
}

.pagination-buttons {
  text-align: right;
}

.pagination-buttons button {
  margin-left: 2px;
  margin-right: 2px;
}
.container {
  margin-top: 10px;
}

.chart-container {
  height: 200px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  
  .login-form {
    width: 90%
  }
  .loans-container {
    margin-top: 50px;
  }
  .loanInfo {
    margin-top: 30px;
  }
  .money-preview h4 {
    padding-top: 15%;
    font-size: 44px;
  }
  #step-one.container, #step-two.container, #step-three.container {
    width: 90%
  }
  .bp4-navbar {
    padding: 0px 0px 0px 7px;
  }
  .bp4-navbar .bp4-button-text {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  #step-one.container, #step-two.container, #step-three.container {
    width: 80%
  }
}